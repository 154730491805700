import useOutsideClick from "@rooks/use-outside-click"
import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import styled, { css } from "styled-components"
import { breakpoints, colors, fontSizes, measure, media } from "../../../styles"
import { useLanguage } from "../../../utils/useLanguage"
import { navItems } from "./navItems"
import { LanguageSelector } from "../../../components/LanguageSelector"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useLocation } from "@reach/router"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const Root = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen
      ? css`
          opacity: 1;
          visibility: visible;
          transition: opacity 0.2s ease-in-out, visibility 0.5s ease-in-out;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
        `}

  /* colors.backgroundStrong */
  background-color: rgba(29, 49, 62, 0.98);

  @supports (backdrop-filter: blur(10px)) {
    background-color: rgba(29, 49, 62, 0.8);
    backdrop-filter: blur(10px);
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100vh;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;

  overflow: auto;
`

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${measure.measure64};

  ${media.smallerThan(breakpoints.medium)} {
    margin-bottom: ${measure.measure48};
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: center;
    margin: 0;

    /* border: 1px dashed blue; */
  }

  /* border: 1px dashed red; */
`

const Li = styled.li<{ active: boolean }>`
  margin: 0;

  & + & {
    margin-top: ${measure.measure48};
  }

  a {
    color: ${colors.gray200};
    text-decoration: none;
    white-space: nowrap;

    text-transform: uppercase;
    font-weight: 300;
    font-size: ${fontSizes.size24};
    line-height: 1;
    letter-spacing: 1.5px;
    white-space: no-wrap;

    &:hover {
      opacity: 80%;
    }

    ${({ active }) =>
      active &&
      css`
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      `}
  }
`

export const MobileMenu = ({ isOpen, onClose }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null)

  const language = useLanguage()
  const { pathname } = useLocation()

  const items = navItems.map(item => ({
    ...item,
    active:
      `${language === "en" ? `/${language}` : ""}${item.url}` === pathname,
  }))

  useOutsideClick(menuRef as MutableRefObject<HTMLDivElement>, () => {
    if (isOpen) {
      onClose()
    }
  })

  const [portalElement, setPortalElement] = useState<HTMLElement>()

  useEffect(() => {
    if (typeof document !== undefined) {
      setPortalElement(document.body)
    }
  }, [portalElement])

  return portalElement
    ? ReactDOM.createPortal(
        <Root isOpen={isOpen} ref={menuRef}>
          <Nav>
            <ul>
              {items.map(({ name, url, active }) => (
                <Li key={name[language]} active={active}>
                  <Link to={url}>{name[language]}</Link>
                </Li>
              ))}
              <Li key="language" active={false}>
                <LanguageSelector />
              </Li>
            </ul>
          </Nav>
        </Root>,
        portalElement
      )
    : null
}
