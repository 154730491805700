import React, { useState } from "react"
import styled from "styled-components"
import {
  breakpoints,
  colors,
  fontSizes,
  measure,
  media,
  shadows,
} from "../../../styles"
import { MobileMenu } from "./MobileMenu"
import { useLanguage } from "../../../utils/useLanguage"
import { Link } from "@reach/router"
import { Squash as Hamburger } from "hamburger-react"

const Root = styled.div`
  background-color: ${colors.backgroundStrong};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${measure.measure72};
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${measure.measure16};
  padding-right: ${measure.measure8};

  ${media.biggerThan(breakpoints.large)} {
    display: none;
  }
`

const Title = styled.h1`
  color: ${colors.gray100};
  text-shadow: ${shadows.main};
  font-size: ${fontSizes.size24};
  line-height: 1;
  margin: 0;
  margin-right: ${measure.measure16};
  font-weight: 300;

  a {
    color: ${colors.white};
    white-space: nowrap;

    &:hover {
      opacity: 80%;
    }
  }
`

const IconContainer = styled.div`
  margin-top: ${measure.measure4};
  z-index: 1000000;
  right: ${measure.measure8};

  &:hover {
    opacity: 80%;
    cursor: pointer;
  }
`

export const MobileHeader = () => {
  const language = useLanguage()
  const titleUrl = language === "en" ? "/en/" : "/"

  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <Root>
        <Title>
          <Link to={titleUrl}>Ana García Lucero</Link>
        </Title>
        <IconContainer style={isMenuOpen ? { position: "fixed" } : {}}>
          <Hamburger
            toggled={isMenuOpen}
            color={colors.gray100}
            distance="lg"
            size={20}
            onToggle={() => setMenuOpen(true)}
          />
        </IconContainer>
      </Root>
      <MobileMenu isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
    </>
  )
}
