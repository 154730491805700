export const fontSizes = {
  size12: "12px",
  size14: "14px",
  size16: "16px",
  size18: "18px",
  size20: "20px",
  size24: "24px",
  size30: "30px",
  size36: "36px",
  size48: "48px",
  size60: "60px",
  size72: "72px",
  size96: "96px",
}
