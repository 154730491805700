export const colors = {
  red: "#ff0000",

  white: "#ffffff",
  black: "#000000",

  grey900: "#212934",
  grey850: "#27272a",
  // grey850: "#26303C",

  grey800: "#404b59",
  grey700: "#6e7a89",
  grey600: "#929fb0",
  grey500: "#afbecc",
  grey400: "#bec5cc",
  grey300: "#d5dde5",
  grey200: "#e1e7ec",
  grey160: "#f0f2f5",
  grey150: "#f3f5f7",
  grey100: "#f8f9fa",

  gray50: "#FAFAFA",
  gray100: "#F4F4F5",
  gray200: "#E4E4E7",
  gray300: "#D4D4D8",
  gray400: "#A1A1AA",
  gray500: "#71717A",
  gray600: "#52525B",
  gray700: "#3F3F46",
  gray800: "#27272A",
  gray900: "#18181B",

  blueGray50: "#F8FAFC",
  blueGray100: "#F1F5F9",
  blueGray200: "#E2E8F0",
  blueGray300: "#CBD5E1",
  blueGray400: "#94A3B8",
  blueGray500: "#64748B",
  blueGray600: "#475569",
  blueGray700: "#334155",
  blueGray800: "#1E293B",
  blueGray900: "#0F172A",

  green: "#0abda0",

  emerald50: "#ECFDF5",
  emerald100: "#D1FAE5",
  emerald200: "#A7F3D0",
  emerald500: "#10B981",
  emerald700: "#047857",

  red50: "#FEF2F2",
  red100: "#FEE2E2",
  red200: "#FECACA",
  red500: "#EF4444",
  red700: "#B91C1C",

  backgroundStrong: "#1D313E",
}
