import React, { useState } from "react"
import styled, { css } from "styled-components"
import { colors, fontSizes, measure } from "../styles"
import useOnWindowScroll from "@rooks/use-on-window-scroll"
import { VscArrowUp } from "react-icons/vsc"
import zenscroll from "zenscroll"

const Root = styled.div<{ isDisplayed: boolean }>`
  position: fixed;
  bottom: ${measure.measure16};
  right: ${measure.measure16};

  height: ${measure.measure48};
  width: ${measure.measure48};

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;

  &:hover {
    cursor: pointer;
  }

  ${({ isDisplayed }) =>
    isDisplayed
      ? css`
          opacity: 1;
          visibility: visible;
          transition: opacity 0.5s ease-in-out, visibility 1s ease-in-out;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease-in-out, visibility 1s ease-in-out;
        `}
`

const Icon = styled(VscArrowUp)`
  color: ${colors.gray400};
  font-size: ${fontSizes.size24};

  &:active  {
    color: ${colors.gray300};
  }
`

export const BackToTopButton = () => {
  const [display, setDisplay] = useState(false)
  useOnWindowScroll(() => setDisplay(window.scrollY > window.innerHeight))

  return (
    <Root isDisplayed={display} onClick={() => zenscroll.toY(0)}>
      <Icon />
    </Root>
  )
}
