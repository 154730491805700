export const measure = {
  measure4: "4px",
  measure8: "8px",
  measure12: "12px",
  measure16: "16px",
  measure24: "24px",
  measure32: "32px",
  measure48: "48px",
  measure64: "64px",
  measure72: "72px",
  measure96: "96px",
  measure128: "128px",
  measure192: "192px",
  measure256: "256px",
  measure384: "384px",
  measure512: "512px",
  measure640: "640px",
  measure768: "768px",
}

export const contentWidths = {
  medium: "720px",
  wide: "1140px",
}
