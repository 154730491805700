import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { useLocation, useNavigate } from "@reach/router"
import { VscTriangleDown } from "react-icons/vsc"
import styled from "styled-components"
import { breakpoints, colors, fontSizes, measure, media } from "../styles"
import { languages, Language } from "../types"

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: ${colors.gray200};
  font-size: ${fontSizes.size14};

  ${media.smallerThan(breakpoints.large)} {
    font-weight: 300;
    font-size: ${fontSizes.size24};
  }

  &:hover {
    opacity: 80%;
  }
`

const TriangleDownIcon = styled(VscTriangleDown)`
  color: ${colors.gray300};
  margin-left: ${measure.measure4};
`

const Select = styled.select`
  opacity: 0;
  position: absolute;
  left: -${measure.measure4};
  width: ${measure.measure64};

  &:hover {
    cursor: pointer;
  }
`

const labels = {
  es: "🇪🇸 Español",
  en: "🇬🇧 English",
}

export const LanguageSelector = () => {
  const { locale } = useLocalization()
  const language = locale as "es" | "en"

  const { pathname } = useLocation()
  const subPath = pathname
    .split("/")
    .filter(part => part.length > 0)
    .filter(part => part !== language)
    .join("/")

  const navigate = useNavigate()

  const onLanguageChange = (lang: Language) => {
    const path = `/${lang === "en" ? "en/" : ""}${subPath}`
    console.log("LANG", lang)
    console.log("PATH", path)
    navigate(path)
  }

  return (
    <Root>
      {language.toUpperCase()} <TriangleDownIcon />
      <Select
        value={language}
        onChange={event => onLanguageChange(event.target.value as Language)}
      >
        {languages.map(lang => (
          <option key={lang} id={lang} value={lang}>
            {labels[lang]}
          </option>
        ))}
      </Select>
    </Root>
  )
}
