import React from "react"
import { Helmet } from "react-helmet"
import { GlobalStyle } from "../styles"

export const PageCommon = () => (
  <>
    <GlobalStyle />
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Ana García Lucero | Cuentacuentos, profesora de inglés, formadora de
        profesores
      </title>
    </Helmet>
  </>
)
