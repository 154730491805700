import { createGlobalStyle } from "styled-components"
import { colors } from "."
import { breakpoints, media } from "./breakpoints"
import { fontSizes } from "./font"
import { measure } from "./measure"

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.gray50};
  }

  a {
    text-decoration: none;
  }

  h1 {
    color: ${colors.gray700};
    font-size: ${fontSizes.size36};
    line-height: 1.25;
    font-weight: 600;
    margin: 0;

    ${media.smallerThan(breakpoints.medium)} {
      font-size: ${fontSizes.size30};
    }
  }

  h2 {
    color: ${colors.gray700};
    font-size: ${fontSizes.size30};
    line-height: 1.25;
    font-weight: 600;
    margin: 0;

    ${media.smallerThan(breakpoints.medium)} {
      font-size: ${fontSizes.size24};
    }
  }

  h1, h2, p:not(:last-child), ul:not(:last-child) {
    margin-bottom: ${measure.measure24};
  }

  p, ul, li, label,input, textarea, span {
    font-size: ${fontSizes.size18};
    color: ${colors.gray700};
    line-height: 1.6;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;

    ${media.smallerThan(breakpoints.medium)} {
      font-size: 19px;
      font-weight: 300;
      color: ${colors.gray900};
    }
  }

  li::marker {
    color: ${colors.gray500};
  }

  label, input, textarea {
    font-size: ${fontSizes.size16};  

    ${media.smallerThan(breakpoints.medium)} {
      font-weight: 400;
    }
  }
`
