import React from "react"
import styled, { css } from "styled-components"
import { measure, media, widths } from "../styles"
import { breakpoints } from "../styles/breakpoints"

type Props = {
  className?: string
  children: React.ReactNode
} & RootProps &
  ContentProps

type RootProps = {
  horizontalPadding?: boolean
}

type ContentProps = {
  maxWidth: "medium" | "wide"
}

export const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${measure.measure128} 0;

  ${media.smallerThan(breakpoints.large)} {
    padding: ${measure.measure128} 0;
  }

  ${media.smallerThan(breakpoints.medium)} {
    padding: ${measure.measure96} 0;
  }

  ${({ horizontalPadding }) =>
    horizontalPadding &&
    css`
      padding-left: ${measure.measure24};
      padding-right: ${measure.measure24};
    `}
`

const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.smallerThan(breakpoints.large)} {
    max-width: 500px;
  }

  ${({ maxWidth }) => {
    switch (maxWidth) {
      case "medium":
        return css`
          max-width: ${widths.medium};
        `
      case "wide":
        return css`
          max-width: ${widths.wide};
        `
    }
  }}
`

export const ContentContainer = ({
  className = undefined,
  horizontalPadding = true,
  maxWidth,
  children,
}: Props) => (
  <Root className={className} horizontalPadding={horizontalPadding}>
    <Content maxWidth={maxWidth}>{children}</Content>
  </Root>
)
