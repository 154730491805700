import React from "react"
import styled from "styled-components"
import { breakpoints, colors, measure, media } from "../styles"
import { useLanguage } from "../utils/useLanguage"

const i18n = {
  es: {
    webBy: "Web por",
    photographyBy: "Fotos y vídeos de",
  },
  en: {
    webBy: "Website by",
    photographyBy: "Photos and videos by",
  },
}

const Root = styled.div`
  height: 256px;
  width: 100%;
  background-color: ${colors.backgroundStrong};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-left: ${measure.measure24};
  padding-right: ${measure.measure24};

  & > * + * {
    margin-left: 16px;
  }

  span,
  a {
    color: ${colors.gray300};
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 1px;

    &:hover {
      opacity: 0.8;
    }
  }

  ${media.smallerThan(breakpoints.large)} {
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`

const Separator = styled.span.attrs({
  children: " | ",
})`
  color: ${colors.gray300};
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 0;
  ${media.smallerThan(breakpoints.large)} {
    display: none;
  }
`

export const Footer = () => {
  const language = useLanguage()
  const content = i18n[language]

  return (
    <Root>
      <span>Ana García Lucero</span>
      <Separator />
      <span>
        {content.webBy}{" "}
        <a
          href="https://twitter.com/_hugocardenas"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hugo Cárdenas
        </a>
      </span>
      <Separator />
      <span>
        {content.photographyBy}{" "}
        <a
          href="http://moifernandez.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Moi Fernández
        </a>
      </span>
    </Root>
  )
}
