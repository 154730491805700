import React from "react"
import styled, { css } from "styled-components"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useLocation } from "@reach/router"
import {
  breakpoints,
  colors,
  fontSizes,
  measure,
  media,
  shadows,
  widths,
} from "../../../styles"
import { navItems } from "./navItems"
import { LanguageSelector } from "../../../components/LanguageSelector"
import { useLanguage } from "../../../utils/useLanguage"
import { VscTriangleDown } from "react-icons/vsc"

type Props = {
  transparent: boolean
}

const Root = styled.div<{ transparent: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${colors.backgroundStrong};

  ${media.smallerThan(breakpoints.large)} {
    display: none;
  }

  height: ${measure.measure128};
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${widths.wide};
  padding: ${measure.measure24};
`

const Title = styled.h1`
  text-shadow: ${shadows.main};
  font-size: ${fontSizes.size30};
  line-height: 1;
  font-weight: 400;
  margin: 0;
  margin-right: ${measure.measure48};

  a {
    color: ${colors.gray100};
    white-space: nowrap;
  }
`

const Nav = styled.nav`
  font-family: "Roboto";
  width: 100%;
  margin-top: 6px;

  ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
  }
`

const Li = styled.li<{ active: boolean }>`
  text-transform: uppercase;
  font-weight: 300;
  font-size: ${fontSizes.size14};
  line-height: 1;
  letter-spacing: 1.5px;
  white-space: no-wrap;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 1000;

  display: flex;
  flex-direction: row;

  & + & {
    margin-left: ${measure.measure48};
  }

  a {
    color: ${colors.gray200};
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      opacity: 80%;
    }

    ${({ active }) =>
      active &&
      css`
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      `}
  }
`

export const DesktopHeader = ({ transparent }: Props) => {
  const language = useLanguage()
  const { pathname } = useLocation()

  const titleUrl = language === "en" ? "/en/" : "/"

  const items = navItems.map(item => ({
    ...item,
    active:
      `${language === "en" ? `/${language}` : ""}${item.url}` === pathname,
  }))

  return (
    <Root transparent={transparent}>
      <Content>
        <Title>
          <Link to={titleUrl}>Ana García Lucero</Link>
        </Title>
        <Nav>
          <ul>
            {items.map(({ name, url, active }) => (
              <Li key={name[language]} active={active}>
                <Link to={url}>{name[language]}</Link>
              </Li>
            ))}
            <Li key="language" active={false}>
              <LanguageSelector />
            </Li>
          </ul>
        </Nav>
      </Content>
    </Root>
  )
}
