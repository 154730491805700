export const breakpoints = {
  medium: 500,
  large: 900,
  extralarge: 1400,
}

export const media = {
  small: `@media (max-width: ${breakpoints.large}px)`,
  large: `@media (min-width: ${breakpoints.large}px)`,

  smallerThan: (width: number) => `@media (max-width: ${width}px)`,
  biggerThan: (width: number) => `@media (min-width: ${width}px)`,
}
